<template>
  <el-dialog
    title="创建模板活动"
    :visible.sync="show"
    width="900px"
    append-to-body
    :before-close="handleClose"
  >
    <el-form label-width="80px">
      <el-form-item label="活动类型">
        <el-radio-group v-model="modelType" style="display:flex" @change="handleSingleChoice">
          <el-radio :label="1">充值金额</el-radio>
          <el-radio :label="2">年费会员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="自定义" v-if="modelType === 1">
        <el-tooltip content="请输入活动金额" placement="top">
          <el-input-number
            style="margin-right:10px"
            :precision="1"
            v-model="amount"
            :min="0"
            placeholder="请输入价位"
          ></el-input-number>
        </el-tooltip>
        <el-tooltip content="请输入赠送金币(红包)" placement="top">
          <el-input-number v-model="free" :min="0" :precision="1" placeholder="赠送书券"></el-input-number>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="自定义" v-else-if="modelType === 2">
        <el-tooltip content="请输入活动金额" placement="top">
          <el-input-number
            style="margin-right:10px"
            :precision="1"
            v-model="amount"
            :min="0"
            placeholder="请输入价位"
          ></el-input-number>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="充值次数">
        <el-select v-model="limit">
          <el-option :value="0" label="不限"></el-option>
          <el-option :value="1" label="1次"></el-option>
          <el-option :value="2" label="2次"></el-option>
          <el-option :value="3" label="3次"></el-option>
          <el-option :value="4" label="4次"></el-option>
          <el-option :value="5" label="5次"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="活动模板" v-if="modelType ===1">
        <el-radio-group style="display:flex" v-model="themeId">
          <div class="card" v-for="item in styleTheme" :key="item.id">
            <img :src="item.cover" alt class="theme-img"/>
            <el-radio :label="item.id">{{ item.name }}</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="活动模板" v-if="modelType ===2">
        <el-radio-group style="display:flex" v-model="themeId">
          <div class="card" v-for="item in vipTheme" :key="item.id">
            <img :src="item.cover" alt class="theme-img"/>
            <el-radio :label="item.id">{{ item.name }}</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>

    </el-form>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {styleTheme, vipTheme} from './kanshuwangtheme'

export default {
  name: 'insertActivity',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      themeId: 1,
      limit: 3,
      styleTheme,
      vipTheme,
      modelType: 1,
      amount: 0,
      free: 0
    }
  },
  methods: {
    handleSingleChoice(val) {
      if (val === 1) {
        this.themeId = 1
      } else {
        this.themeId = 7
      }
    },
    handleSure() {
      const {type, themeId, limit, modelType, amount, free} = this
      if (!themeId) {
        this.$message.error('请选择模板')
        return false
      }
      if (modelType === 1 && (!amount || !free)) {
        this.$message.error('请完善自定义金额和赠送金币(红包)!')
        return false
      }
      if (modelType === 2 && !amount) {
        this.$message.error('请完善自定义金额!')
        return false
      }
      // "{tpl:(\\d+?),amount:(\\d+?),free:(\\d+?),limit:(\\d+?)}";
      let name
      if (modelType === 1) {
        name = `充${amount}赠送${free}金币`
      } else {
        name = `年费充值${amount}`
      }
      let returnData = null
      if (type === 'text') {
        returnData = [`<a href="{tpl:${themeId},amount:${amount},free:${free},limit:${limit}}">${name}</a>`]
      } else {
        returnData = {
          link: `<a href="{tpl:${themeId},amount:${amount},free:${free},limit:${limit}}">${name}</a>`,
          name: name,
          url: `{tpl:${themeId},amount:${amount},free:${free},limit:${limit}}`
        }
      }
      this.$emit('success', returnData)
      this.handleClose()
    },
    handleClose() {
      this.themeId = 1
      this.limit = 3
      this.amount = 0
      this.free = 0
      this.modelType = 1
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/ .el-drawer {
  padding: 20px;

  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.card {
  width: 120px;
  border: 1px solid #ececec;
  margin-right: 15px;
  text-align: center;
}

.theme-tit {
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #393939;
  font-size: 14px;
}

.theme-img {
  width: 100%;
  height: 192px;
}

.el-radio {
  display: block;
  margin-top: 8px;
  margin-left: 0;
}

.el-checkbox {
  display: block;
  margin-left: 0;
}

.theme-cont {
  height: 500px;
  overflow: auto;
}

.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
