<template>
  <div class="insert-btn-cont">
    <el-button
      class="button-mini"
      type="text"
      @click="handleDialogShow"
      style="margin-right: 10px"
      >插入书籍</el-button
    >
    <component
      :is="currentView"
      :show="showTemplate"
      :account-id="channel ? channel.id : null"
      @close="showTemplate = false"
      @success="handleSelectSuccess"
    ></component>
  </div>
</template>

<script>
import YueWenDialog from "./yuewen";
import ReadDialog from "./read";
import SunshineDialog from "./sunshine";
import BookHouseDialog from "./bookhouse";
import WangYiDialog from "./wangyi";
import QiYueDialog from "./qiyue";
import HuaShengDialog from "./huasheng";
import HaiDuDialog from "./haidu";
import KanShuWangDialog from "./kanshuwang";

export default {
  name: "insertBook",
  props: {
    channel: Object,
  },
  data() {
    return {
      showTemplate: false,
      currentView: null,
    };
  },
  components: {
    ReadDialog,
    YueWenDialog,
    SunshineDialog,
    BookHouseDialog,
    QiYueDialog,
    HuaShengDialog,
    HaiDuDialog,
    WangYiDialog,
    KanShuWangDialog
  },
  methods: {
    handleDialogShow() {
      if (this.channel) {
        const { platform } = this.channel;
        switch (platform) {
          case 0:
            this.currentView = "ReadDialog";
            break;
          case 1:
            this.currentView = "YueWenDialog";
            break;
          case 3:
            this.currentView = "SunshineDialog";
            break;
          case 4:
            this.currentView = "BookHouseDialog";
            break;
          case 5:
            this.currentView = "WangYiDialog";
            break;
          case 6:
            this.currentView = "QiYueDialog";
            break;
          case 7:
            this.currentView = "HuaShengDialog";
            break;
          case 8:
            this.currentView = "HaiDuDialog";
            break;
          case 12:
            this.currentView = "WangYiDialog";
            break;
          case 14:
            this.currentView = "KanShuWangDialog";
            break;
        }
        this.showTemplate = true;
      } else {
        this.$message.warning("请选择公众号");
      }
    },
    handleSelectSuccess(select) {
      this.$emit("success", select);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.insert-btn-cont {
  display: inline-block;
}
</style>
