export const styleTheme = [
  {
    id: 1,
    name: '默认风格',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/default/banner.png'
  },
  {
    id: 2,
    name: '男生风格',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/boy/banner.png'
  },
  {
    id: 3,
    name: '女生风格',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/girl/banner.png'
  },
  {
    id: 6,
    name: '周末风格',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/weekend/banner.png'
  }
]

export const holidayTheme = [
  {
    id: 4,
    name: '教师节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/teacher/banner.png'
  },
  {
    id: 5,
    name: '中秋节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/midautumn/banner.png'
  },
  {
    id: 7,
    name: '国庆节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/national1/banner.png'
  },
  {
    id: 8,
    name: '重阳节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/double9/banner.png'
  },
  {
    id: 9,
    name: '万圣节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/halloween/banner.png'
  },
  {
    id: 10,
    name: '双十一',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/double11_1/banner.png'
  },
  {
    id: 11,
    name: '双十二',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/double12/banner.png'
  },
  {
    id: 12,
    name: '圣诞节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/christmas/banner.png'
  },
  {
    id: 13,
    name: '元旦节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/newyear/banner.png'
  },
  {
    id: 16,
    name: '春节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/spring/banner.png'
  },
  {
    id: 17,
    name: '元宵节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/lantern20/banner.png'
  },
  {
    id: 18,
    name: '情人节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/valentine/banner.png'
  },
  {
    id: 19,
    name: '春季踏青',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/taqing/banner.png'
  },
  {
    id: 20,
    name: '劳动节',
    cover: 'https://ywopen-1252317822.file.myqcloud.com/openwx/wechatactivity/template/laborday/banner.png'
  }
]

export const levelList = [
  {
    id: 4,
    label: '充5.5得550书币+450书券'
  },
  {
    id: 5,
    label: '充9.9得990书币+990书券'
  },
  {
    id: 1,
    label: '充15得1500书币+1000书券'
  },
  {
    id: 6,
    label: '充25得2500书币+2500书券'
  },
  {
    id: 7,
    label: '充31得3100书币+2900书券'
  },
  {
    id: 8,
    label: '充45得4500书币+4500书券'
  },
  {
    id: 2,
    label: '充55得5500书币+4500书券'
  }, {
    id: 9,
    label: '充66得6600书币+9900书券'
  },
  {
    id: 3,
    label: '充85得8500书币+7500书券'
  },
  {
    id: 10,
    label: '充95得9500书币+9500书券'
  },
  {
    id: 12,
    label: '充3得300书币'
  },
  {
    id: 11,
    label: '充1得100书币'
  }
]

export const readLevelList = [
  {
    id: 99,
    label: '充 1 送 500 书券'
  },
  {
    id: 86,
    label: '充 3 送 300 书券'
  },
  {
    id: 49,
    label: '充 5 送 500 书券'
  },
  {
    id: 50,
    label: '充 9 送 900 书券'
  },
  {
    id: 80,
    label: '充 9 送 2000 书券'
  },
  {
    id: 94,
    label: '充 12 无赠送'
  },
  {
    id: 44,
    label: '充 12 送 1200 书券'
  },
  {
    id: 100,
    label: '充 13 送 1400 书券'
  },
  {
    id: 54,
    label: '充 15 送 1000 书券'
  },
  {
    id: 48,
    label: '充 15 送 1500 书券'
  },
  {
    id: 38,
    label: '充 18 送 800 书券'
  },
  {
    id: 81,
    label: '充 18 送 2500 书券'
  },
  {
    id: 76,
    label: '充 19 送 1900 书券'
  },
  {
    id: 101,
    label: '充 19 送 2100 书券'
  },
  {
    id: 32,
    label: '充 20 无赠送'
  },
  {
    id: 56,
    label: '充 23 无赠送'
  },
  {
    id: 59,
    label: '充 23 送 1000 书券'
  },
  {
    id: 82,
    label: '充 25 送 3500 书券'
  },
  {
    id: 77,
    label: '充 28 送 2200 书券'
  },
  {
    id: 47,
    label: '充 28 送 2800 书券'
  },
  {
    id: 52,
    label: '充 30 送 800 书券'
  },
  {
    id: 89,
    label: '充 30 送 1000 书券'
  },
  {
    id: 51,
    label: '充 30 送 1500 书券'
  },
  {
    id: 43,
    label: '充 36 送 3600 书券'
  },
  {
    id: 87,
    label: '充 38 送 4800 书券'
  },
  {
    id: 91,
    label: '充 48 送 3800 书券'
  },
  {
    id: 109,
    label: '充 48 送 5200 书券'
  },
  {
    id: 72,
    label: '充 49 送 3000 书券'
  },
  {
    id: 45,
    label: '充 50 送 2000 书券'
  },
  {
    id: 88,
    label: '充 66 送 3600 书券'
  },
  {
    id: 39,
    label: '充 66 送 6600 书券'
  },
  {
    id: 98,
    label: '充 78 送 5800 书券'
  },
  {
    id: 46,
    label: '充 88 送 10000 书券'
  },
  {
    id: 96,
    label: '充 100 送 15000 书券'
  },
  {
    id: 97,
    label: '充 200 送 20000 书券'
  },
  {
    id: 40,
    label: '充 9.9 送 590 书券 , 再送4元优惠券'
  },
  {
    id: 41,
    label: '充 16 送 700 书券 , 再送9元优惠券'
  },
  {
    id: 42,
    label: '充 19 送 1000 书券 , 再送9元优惠券'
  },
  {
    id: 83,
    label: '充 28 送 2000 书券 , 再送8元优惠券'
  },
]
