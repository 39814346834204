export const styleTheme = [
  {
    id: 2,
    name: '普通模板',
    cover: 'https://static.youshuge.com/ActivityMould/mVxHSZm0oDpOFOp0SwTcOB4aR4hoDwA8L70b3h9r.png'
  },
  {
    id: 3,
    name: '普通模板2',
    cover: 'https://static.youshuge.com/ActivityMould/y5rnucAeTwY1JKQx392OekexWkBxCBTygb3iitc7.png'
  },
  {
    id: 12,
    name: '普通模板3',
    cover: 'https://static.youshuge.com/ActivityMould/rMz2leZlqxhparNuyePE79mmRyNuREpWzaRLnOF6.png'
  },
  {
    id: 13,
    name: '普通模板4',
    cover: 'https://static.youshuge.com/ActivityMould/DhuKNplGu77ENtfiLK0G2elzAwD64PFLDA8KogX2.png'
  }
]

export const holidayTheme = [
  {
    id: 4,
    name: '五一模板',
    cover: 'https://static.youshuge.com/ActivityMould/bdunYEMZhUHgU2DIpoy154Mu7WgjtbHvfN7D8TEV.jpeg'
  },
  {
    id: 10,
    name: '520活动',
    cover: 'https://static.youshuge.com/ActivityMould/d5M02Dyd8tkDK08jCe2VPsJNolvUo6lTT5WUOTLw.png'
  },
  {
    id: 15,
    name: '端午节模板',
    cover: 'https://static.youshuge.com/ActivityMould/w40EZ2civl70g1AtkihnzfrrLvXzg4WCOoF6gtk4.png'
  },
  {
    id: 23,
    name: '国庆节日无优惠券',
    cover: 'https://static.youshuge.com/ActivityMould/Ok2Fg1IylC5yk1VOqZ8SvkTEq2bEWZjyEJ4DXvLx.png'
  }
]

export const levelList = [
  {
    id: 1,
    label: '充5.5送4.5'
  },
  {
    id: 2,
    label: '充9.9送9.9'
  },
  {
    id: 3,
    label: '充15送10'
  },
  {
    id: 4,
    label: '充25送25'
  },
  {
    id: 5,
    label: '充31送29'
  },
  {
    id: 6,
    label: '充45送45'
  },
  {
    id: 7,
    label: '充55送45'
  }, {
    id: 8,
    label: '充66送99'
  },
  {
    id: 9,
    label: '充85送75'
  },
  {
    id: 10,
    label: '充95送95'
  },
  {
    id: 11,
    label: '充19元送10元书券+9元现金抵扣券'
  },
  {
    id: 12,
    label: '充16送7元书券+9元现金抵扣券'
  },
  {
    id: 13,
    label: '充9.9送5.9书券+4元现金抵扣券'
  }
]
