<template>
  <el-dialog
    title="创建模板活动"
    :visible.sync="show"
    width="700px"
    append-to-body
    top="5vh"
    :before-close="handleClose"
  >
    <div class="service-inserActivity-sunshine">
      <el-form
        label-width="80px"
        style="height: 500px; overflow: auto"
        v-loading="loading"
      >
        <!-- <el-form-item label="活动主题">
          <el-popover placement="bottom" width="250" trigger="hover">
            <img
              src="https://scss.lingyun5.com/chunqiu/ActThemeMods/default.png"
              alt=""
              class="theme-img"
            />
            <el-radio slot="reference" v-model="theme" label="1"
              >默认主题</el-radio
            >
          </el-popover>
          <el-popover placement="bottom" width="250" trigger="hover">
            <img
              src="https://scss.lingyun5.com/chunqiu/ActThemeMods/girl.png"
              alt=""
              class="theme-img"
            />
            <el-radio
              slot="reference"
              v-model="theme"
              label="2"
              style="margin-left: 15px"
              >女生主题</el-radio
            >
          </el-popover>
          <el-popover placement="bottom" width="250" trigger="hover">
            <img
              src="https://scss.lingyun5.com/chunqiu/ActThemeMods/boy.png"
              alt=""
              class="theme-img"
            />
            <el-radio
              slot="reference"
              v-model="theme"
              label="3"
              style="margin-left: 15px"
              >男生主题</el-radio
            >
          </el-popover>
        </el-form-item> -->
        <el-form-item label="充值类型">
          <el-select v-model="activeType">
            <el-option :value="0" label="普通充值"></el-option>
            <el-option :value="1" label="vip会员"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值项" v-if="activeType">
          <el-select v-model="customForm.level">
            <el-option
              :value="item.value"
              :label="item.label"
              :key="index"
              v-for="(item, index) in leveList"
            ></el-option>
          </el-select>
        </el-form-item>
        <template v-if="!activeType">
          <el-form-item label="充值金额">
            <tinyt-input
              width="216"
              placeholder="0~300.00"
              v-model="customForm.price"
              isNum
              max="300"
              min="0"
            />
            <span style="margin-left: 10px">* 介于0~300元间，最多2位小数</span>
          </el-form-item>
          <el-form-item label="赠送金额" class="send-money">
            <tinyt-input
              width="216"
              placeholder="赠送金额"
              v-model="customForm.redEnvelope"
              isNum
              max="300"
              min="0"
            />
            <span style="margin-left: 10px; width: 360px"
              >*
              充值金额为0~0.99元时，赠送金额不超过9元；1元~9元，赠送金额不超过18元；大于9元的，赠送金额不超过充值金额的1.5倍。</span
            >
          </el-form-item>
        </template>
        <el-form-item label="限购次数">
          <el-select v-model="customForm.limit">
            <el-option :value="-1" label="不限"></el-option>
            <el-option :value="1" label="1次"></el-option>
            <el-option :value="2" label="2次"></el-option>
            <el-option :value="3" label="3次"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="充值档位">
          <template>
            <el-radio-group v-model="customForm.level" v-if="type === 'news'">
              <el-radio
                :label="item.configId"
                v-for="item in dataList"
                :key="item.configId"
                class="radio"
                @change="handleSingleChoice"
                >{{
                  item.rechargeProduct.title +
                  (item.hongbao && item.hongbao.name
                    ? "送" + item.hongbao.name
                    : "")
                }}
              </el-radio>
            </el-radio-group>
            <el-checkbox-group
              v-model="customForm.levelCheck"
              v-else
              @change="handleChange"
            >
              <el-checkbox
                style="min-width: 220px"
                v-for="item in dataList"
                :key="item.configId"
                :label="item.configId"
                >{{
                  item.rechargeProduct.title +
                  (item.hongbao && item.hongbao.name
                    ? "送" + item.hongbao.name
                    : "")
                }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
        </el-form-item> -->
      </el-form>
      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getSunshineActivity } from "../../../api/service";

export default {
  name: "sunshineActivity",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    accountId: Number,
  },
  data() {
    return {
      // tab: '1',
      // theme: "1",
      dataList: [],
      // presentList: [],
      loading: false,
      customForm: {},
      // presentForm: {}
      onceLimit: [
        1032008, 1033007, 1029008, 1032013, 3001, 3006, 1011009, 1032009,
        1032012,
      ],
      activeType: 0,
      leveList: [
        { value: 1023002, label: "365元包一年" },
        { value: 1024002, label: "399元包一年" },
        { value: 1021003, label: "499元包一年" },
        { value: 1025002, label: "666元包一年" },
      ],
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          // this.getData();
          this.customForm = { limit: 1 };
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(val) {
      let tmpFlag = val.some((item) => {
        return this.onceLimit.includes(item);
      });
      if (tmpFlag) {
        this.customForm.limit = 1;
      } else {
        this.customForm.limit = 3;
      }
    },
    handleSingleChoice(val) {
      this.customForm.limit = this.onceLimit.includes(val) ? 1 : 3;
    },
    getData() {
      this.loading = true;
      getSunshineActivity(this.accountId, 1)
        .then((res) => {
          this.dataList = res;
          this.customForm.levelCheck = [res[0].configId];
          this.customForm.level = res[0].configId;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSure() {
      const { type, customForm } = this;
      if (!this.activeType) {
        // 普通充值
        if (!customForm.price || !customForm.redEnvelope || !customForm.limit) {
          return this.$message.error("请填写完整数据");
        }
        // vip会员
      } else {
        if (!customForm.level || !customForm.limit) {
          return this.$message.error("请填写完整数据");
        }
      }
      let returnData = null;
      const { price, redEnvelope, limit, level } = customForm;
      let tmplevel = this.leveList.find((item) => {
        return item.value == level;
      });
      if (type === "text") {
        // 普通充值
        if (!this.activeType) {
          returnData = [
            `<a href="{price:${price},redEnvelope:${redEnvelope},limit:${limit}}">充值${price}元</a>`,
          ];
        } else {
          returnData = [
            `<a href="{level:${level},limit:${limit}}">${tmplevel.label}</a>`,
          ];
        }
      } else {
        // 普通充值
        if (!this.activeType) {
          returnData = {
            name: `充值${price}元`,
            url: `{price:${price},redEnvelope:${redEnvelope},limit:${limit}}`,
          };
        } else {
          returnData = {
            name: tmplevel.label,
            url: `{level:${level},limit:${limit}}`,
          };
        }
      }
      this.$emit("success", returnData);
      this.handleClose();
    },
    handleClose() {
      // this.theme = "1";
      this.$emit("close");
    },
  },
};
</script>

<style lang='scss' scoped>
.el-checkbox,
.radio {
  display: inline-block;
  line-height: 27px;
  min-width: 200px;
  margin-left: 0;
  margin-top: 0;
}

.radio {
  margin-top: 10px;
}

.radio-group {
  height: 520px;
  overflow: auto;
  padding: 20px 0;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
.theme-img {
  width: 220px;
  height: 346px;
}
/deep/ .send-money .el-form-item__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
