<template>
  <el-dialog
      title="选择页面"
      :visible.sync="show"
      width="800px"
      top="5vh"
      append-to-body
      :before-close="handleClose">
    <el-table
        :data="pageList"

        @current-change="(row)=>this.currentPage = row"
        highlight-current-row
        style="width: 100%">
      <el-table-column
          prop="select"
          label="选择"
          width="50">
        <template slot-scope="scope">
          <el-radio type="radio" v-model="form.cbid" :label="scope.row.cbid"
                    style="font-size: 0"></el-radio>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="名称">
        <template slot-scope="scope">
          <span v-if="scope.row.title">{{ scope.row.title }}</span>
          <div v-else>
            <el-form ref="form" size="small" inline :model="form" label-width="80px">
              <!--<el-form-item  style="margin-bottom: 0">-->
              <!--  <el-input v-model="form.title" placeholder="链接标题"></el-input>-->
              <!--</el-form-item>-->
              <el-form-item label="链接地址:" style="margin-bottom: 0">
                <el-input v-model="form.url" placeholder="请输入链接地址"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'
export default {
  name: 'insertPage',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pageList: [
        { cbid: 1, title: '书城首页', url: '1' },
        { cbid: 2, title: '排行榜(自动)', url: '2' },
        { cbid: 3, title: '排行榜(男生)', url: '3' },
        { cbid: 4, title: '排行榜(女生)', url: '4' },
        { cbid: 5, title: '充值页', url: '5' },
        { cbid: 6, title: '最近阅读列表', url: '6' },
        { cbid: 13, title: '限免阅读', url: '7' },
        { cbid: 8, title: '每日签到', url: '8' },
        { cbid: 9, title: '个人中心', url: '9' },
        { cbid: 10, title: '置顶公众号引导', url: '10' },
        { cbid: 11, title: '新用户充值活动页', url: '11' },
        { cbid: 12, title: '最近阅读作品', url: '12' },
        { cbid: 7 }
      ],
      currentPage: {},
      form: {
        // title: '',
        url: '',
        cbid: ''
      }
    }
  },
  methods: {
    handleConfirm () {
      const curPage = this.currentPage
      if (curPage.cbid) {
        if (curPage.cbid === 7) {
          const data = this.form
          if (data.url) {
            this.$emit('success', {
              link: `<a href="${data.url}"></a>`,
              name: data.url,
              url: `${data.url}`
            })
            this.handleClose()
          } else {
            this.$message.warning('请输入自定义链接')
          }
        } else {
          this.$emit('success', {
            link: `<a href="{pagelink:${curPage.url},pageName:${Base64.stringify(Utf8.parse(curPage.title))}}">${curPage.title}</a>`,
            name: curPage.title,
            url: `{pagelink:${curPage.url},pageName:${Base64.stringify(Utf8.parse(curPage.title))}}`
          })
          this.handleClose()
        }
      } else {
        this.$message.warning('请选择一个页面')
      }
    },
    handleClose () {
      this.form = {}
      this.currentPage = {}
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
  .el-form--inline /deep/ .el-form-item__content {
    width: 300px;
  }
</style>
