<template>
  <el-dialog
    title="创建模板活动"
    :visible.sync="show"
    width="700px"
    append-to-body
    top="5vh"
    :before-close="handleClose"
  >
    <div class="service-inserActivity-sunshine">
      <el-form
        label-width="80px"
        style="height: 500px; overflow: auto"
        v-loading="loading"
      >
        <el-form-item label="充值次数">
          <el-select v-model="customForm.limit">
            <el-option :value="0" label="不限"></el-option>
            <el-option :value="1" label="1次"></el-option>
            <el-option :value="2" label="2次"></el-option>
            <el-option :value="3" label="3次"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值档位">
          <template>
            <el-radio-group v-model="customForm.level" v-if="type === 'news'">
              <el-radio
                :label="item.value"
                v-for="item in dataList"
                :key="item.value"
                class="radio"
                @change='handleSingleChoice'
                >{{ item.title }}
              </el-radio>
            </el-radio-group>
            <el-checkbox-group
              v-model="customForm.levelCheck"
              v-else
              @change="handleChange"
            >
              <el-checkbox
                v-for="item in dataList"
                :key="item.value"
                :label="item.value"
                >{{ item.title }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getQiYueActivity } from '../../../api/service'

export default {
  name: 'sunshineActivity',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    accountId: Number
  },
  data () {
    return {
      // tab: '1',
      dataList: [],
      // presentList: [],
      loading: false,
      customForm: {}
      // presentForm: {}
    }
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          this.getData()
          this.customForm = { limit: 1, levelCheck: [], level: null }
        }
      },
      immediate: true
    }
  },
  methods: {
    handleChange (val) {
      if (
        val.includes('977211567746726349') ||
        val.includes('631011594286168715') ||
        val.includes('634391594286125930')
      ) {
        this.customForm.limit = 1
      } else {
        this.customForm.limit = 3
      }
    },
    handleSingleChoice(val){
       this.customForm.limit = ['977211567746726349','631011594286168715','634391594286125930'].includes(val) ? 1 : 3
    },
    getData () {
      this.loading = true
      getQiYueActivity(this.accountId, 1)
        .then((res) => {
          this.dataList = res
          this.customForm.levelCheck = [res[0].value]
          this.customForm.level = res[0].value
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSure () {
      const { type, dataList, customForm } = this
      if (type === 'text' && customForm.levelCheck.length === 0) {
        this.$message.error('请选择充值档位')
        return false
      }
      let returnData = null
      const { limit } = customForm
      if (type === 'text') {
        const { levelCheck } = customForm
        returnData = levelCheck.map((level) => {
          const levelName = dataList.find((item) => item.value === level).title
          return `<a href="{level:${level},limit:${limit}}">${levelName}</a>`
        })
      } else {
        const { level } = customForm
        const { title } = dataList.find((item) => item.value === level)
        returnData = {
          name: title,
          url: `{level:${level},limit:${limit}}`
        }
      }
      // const limit = tab === '1' ? customForm.limit : presentForm.limit;
      // const currentList = tab === '1' ? dataList : presentList;
      // if (type === 'text') {
      //   const levelCheck = tab === '1' ? customForm.levelCheck : presentForm.levelCheck;
      //   returnData = levelCheck.map(level => {
      //     const levelName = currentList.find(item => item.value === level).title;
      //     return `<a href="{level:${level},limit:${limit}}">${levelName}</a>`
      //   })
      // } else {
      //   const level = tab === '1' ? customForm.level : presentForm.level;
      //   const {title} = currentList.find(item => item.value === level);
      //   returnData = {
      //     name: title,
      //     url: `{level:${level},limit:${limit}}`
      //   }
      // }
      this.$emit('success', returnData)
      this.handleClose()
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.el-checkbox,
.radio {
  display: inline-block;
  line-height: 27px;
  min-width: 200px;
  margin-left: 0;
  margin-top: 0;
}

.radio {
  margin-top: 10px;
}

.radio-group {
  height: 520px;
  overflow: auto;
  padding: 20px 0;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
</style>
