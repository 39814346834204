export const styleTheme = [
  {
    id: 1,
    name: '1',
    cover: 'https://admin.huangbianyu.cn/upload/image/2021/02/24/202102240346059566443.png'
  },
  {
    id: 2,
    name: '2',
    cover: 'https://admin.huangbianyu.cn/upload/image/2021/02/24/20210224034636124217.png'
  },
  {
    id: 3,
    name: '3',
    cover: 'https://admin.huangbianyu.cn/upload/image/2021/02/24/202102240347073544264.png'
  },
  {
    id: 4,
    name: '4',
    cover: 'https://admin.huangbianyu.cn/upload/image/2021/02/24/202102240347527728006.png'
  },
  {
    id: 5,
    name: '5',
    cover: 'https://admin.huangbianyu.cn/upload/image/2021/02/24/202102240348358553847.png'
  },
  {
    id: 6,
    name: '6',
    cover: 'https://admin.huangbianyu.cn/upload/image/2021/02/24/202102240348566765883.png'
  }
]

export const vipTheme = [
  {
    id: 7,
    name: '年费会员',
    cover: 'https://admin.huangbianyu.cn/upload/image/2021/10/28/2021102805553148046.png'
  }
]
