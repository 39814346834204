<template>
  <el-dialog
    title="创建模板活动"
    :visible.sync="show"
    width="600px"
    append-to-body
    :before-close="handleClose"
  >
    <el-form label-width="80px">
      <el-form-item label="活动主题">
        <el-tag type="success" size="default">{{ theme.name }}</el-tag>
        <el-button @click="showThemeDialog = true" type="text" style="margin-left: 10px">选择活动主题</el-button>
      </el-form-item>
      <el-form-item label="选择模式">
        <el-radio-group v-model="modelType" style="display:flex">
          <el-radio :label="1">充值档位</el-radio>
          <el-radio :label="2">自定义档位</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="充值档位" v-if="modelType === 1">
        <template>
          <el-radio-group v-model="level" v-if="type === 'news'" @change="handleSingleChoice">
            <el-radio :label="item.id" v-for="item in levelList" :key="item.id">{{ item.label }}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-model="levelCheck" v-else @change="handleChange">
            <el-checkbox v-for="item in levelList" :key="item.id" :label="item.id">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </template>
      </el-form-item>
      <el-form-item label="自定义" v-if="modelType === 2">
        <el-tooltip content="请输入价位" placement="top">
          <el-input-number
            style="margin-right:10px"
            :precision="1"
            v-model="amount"
            :min="0"
            placeholder="请输入价位"
          ></el-input-number>
        </el-tooltip>
        <el-tooltip content="请输入需要赠送的书券值" placement="top">
          <el-input-number v-model="free" :min="0" :precision="0" placeholder="赠送书券"></el-input-number>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="充值次数">
        <el-select v-model="limit">
          <el-option :value="1" label="1次"></el-option>
          <el-option :value="2" label="2次"></el-option>
          <el-option :value="3" label="3次"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
    <el-dialog
      title="选择主题"
      :visible.sync="showThemeDialog"
      width="900px"
      top="5vh"
      append-to-body
      :before-close="() => this.showThemeDialog = false"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="风格主题">
          <div class="row theme-cont">
            <div
              class="card"
              v-for="item in styleTheme"
              :key="item.id"
              @click="handleSelectTheme(item)"
            >
              <p class="theme-tit">{{ item.name }}</p>
              <img :src="item.cover" alt class="theme-img" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="节日主题">
          <div class="row theme-cont">
            <div
              class="card"
              v-for="item in holidayTheme"
              :key="item.id"
              @click="handleSelectTheme(item)"
            >
              <p class="theme-tit">{{ item.name }}</p>
              <img :src="item.cover" alt class="theme-img" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="dialog-footer">
        <el-button @click="showThemeDialog = false">取 消</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { styleTheme, holidayTheme, levelList } from './theme'

export default {
  name: 'insertActivity',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data () {
    return {
      theme: {
        id: 1,
        name: '默认风格'
      },
      level: 4,
      levelCheck: [4],
      limit: 3,
      showThemeDialog: false,
      styleTheme,
      holidayTheme,
      levelList,
      modelType: 1,
      amount: '',
      free: ''
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.handleChange([this.levelList[0].id])
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSelectTheme (item) {
      this.theme = item
      this.showThemeDialog = false
    },
    handleChange (val) {
      if (val.includes(11) || val.includes(12) || val.includes(5) || val.includes(4)) {
        this.limit = 1
      } else {
        this.limit = 3
      }
    },
    handleSingleChoice (val) {
      this.limit = [11, 12, 5, 4].includes(val) ? 1 : 3
    },
    handleSure () {
      const { level, theme, limit, levelList, type, levelCheck, modelType, free, amount } = this
      if (type === 'text' && modelType === 1 && levelCheck.length === 0) {
        this.$message.error('请选择充值档位')
        return false
      }
      if (type === 'text' && modelType === 2 && (!amount || !free)) {
        this.$message.error('请完善自定义档位的金额和书券!')
        return false
      }
      let returnData = null
      if (type === 'text') {
        if (modelType === 1) {
          returnData = levelCheck.map((level) => {
            const levelName = levelList.find((item) => item.id === level).label
            return `<a href="{tpl:${theme.id},level:${level},limit:${limit}}">${levelName}</a>`
          })
        }
        if (modelType === 2) {
          returnData = [`<a href="{tpl:${theme.id},amount:${amount},free:${free},limit:${limit}}">充${amount}得${amount * 100}书币+${free}书券</a>`]
        }
      } else {
        if (modelType === 1) {
          const name = levelList.find((item) => item.id === level).label
          returnData = {
            // link: `<a href="{tpl:${theme.id},level:${level},limit:${limit}}">${name}</a>`,
            name,
            url: `{tpl:${theme.id},level:${level},limit:${limit}}`
          }
        }
        if (modelType === 2) {
          const name = `充${amount}得${amount * 100}书币+${free}书券`
          returnData = {
            name,
            url: `{tpl:${theme.id},amount:${amount},free:${free},limit:${limit}}`
          }
        }
      }
      this.$emit('success', returnData)
      this.handleClose()
    },
    handleClose () {
      this.theme = {
        id: 1,
        name: '默认风格'
      }
      this.limit = 3
      this.level = 4
      this.levelCheck = [4]
      this.amount = ''
      this.free = ''
      this.modelType = 1
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.card {
  width: 250px;
  border: 1px solid #ececec;
  margin-top: 15px;
  margin-right: 15px;
}

.theme-tit {
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #393939;
  font-size: 14px;
}

.theme-img {
  width: 100%;
  height: 192px;
}

.el-radio {
  display: block;
  margin-top: 8px;
  margin-left: 0;
}
.el-checkbox {
  display: block;
  margin-left: 0;
}
.theme-cont {
  height: 500px;
  overflow: auto;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
