<template>
  <el-dialog
    title="创建模板活动"
    :visible.sync="show"
    width="700px"
    top="10vh"
    append-to-body
    :before-close="handleClose"
  >
    <el-tabs type="border-card" v-model="tabName">
      <el-tab-pane label="自定义活动" name="custom">
        <el-form label-width="80px" style="height: 520px; overflow: auto">
          <el-form-item label="活动主题">
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="http://ad.rlcps.cn/dist/nj/style/res/template1.png"
                alt=""
                class="theme-img"
              />
              <el-radio slot="reference" v-model="theme" label="1"
                >模板1</el-radio
              >
            </el-popover>
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="http://ad.rlcps.cn/dist/nj/style/res/template2.png"
                alt=""
                class="theme-img"
              />
              <el-radio
                slot="reference"
                v-model="theme"
                label="2"
                style="margin-left: 15px"
                >模板2</el-radio
              >
            </el-popover>
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="http://ad.rlcps.cn/dist/nj/style/res/template3.png"
                alt=""
                class="theme-img"
              />
              <el-radio
                slot="reference"
                v-model="theme"
                label="3"
                style="margin-left: 15px"
                >模板3</el-radio
              >
            </el-popover>
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="http://ad.rlcps.cn/dist/nj/style/res/template4.png"
                alt=""
                class="theme-img"
              />
              <el-radio
                slot="reference"
                v-model="theme"
                label="4"
                style="margin-left: 15px"
                >模板4</el-radio
              >
            </el-popover>
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="http://ad.rlcps.cn/dist/nj/style/res/template10.png"
                alt=""
                class="theme-img"
              />
              <el-radio
                slot="reference"
                v-model="theme"
                label="10"
                style="margin-left: 15px"
                >模板10</el-radio
              >
            </el-popover>
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="http://ad.rlcps.cn/dist/nj/style/res/template11.png"
                alt=""
                class="theme-img"
              />
              <el-radio
                slot="reference"
                v-model="theme"
                label="11"
                style="margin-left: 15px"
                >模板11</el-radio
              >
            </el-popover>
          </el-form-item>
          <el-form-item label="充值档位">
            <template>
              <el-radio-group
                v-model="level"
                v-if="type === 'news'"
                @change="handleSingleChoice"
              >
                <el-radio
                  :label="item.product_id"
                  v-for="item in levelList"
                  :key="item.product_id"
                  class="radio"
                  >{{ item.name }}
                </el-radio>
              </el-radio-group>
              <el-checkbox-group
                v-model="levelCheck"
                v-else
                @change="handleChange"
              >
                <el-checkbox
                  v-for="item in levelList"
                  :key="item.product_id"
                  :label="item.product_id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
              <p class="tips">
                书券有效期默认 365 天；优惠券7天有效 充值金额大于30元才可使用
              </p>
            </template>
          </el-form-item>
          <el-form-item label="充值次数">
            <el-select v-model="limit">
              <el-option :value="1" label="1次"></el-option>
              <el-option :value="2" label="2次"></el-option>
              <el-option :value="3" label="3次"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="平台活动" name="platform">
        <div v-loading="loading" class="radio-group">
          <template v-if="activityList.length">
            <el-radio-group v-model="url" v-if="type === 'news'">
              <el-radio
                :label="item.act_id"
                v-for="item in activityList"
                :key="item.act_id"
                class="radio"
                >{{ item.name }}
              </el-radio>
            </el-radio-group>
            <el-checkbox-group v-model="urlCheck" v-else>
              <el-checkbox
                v-for="item in activityList"
                :key="item.act_id"
                :label="item.act_id"
                >{{
                  item.name + "&nbsp;&nbsp;&nbsp;(" + item.intro + ")"
                }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
          <p v-else>暂无自定义活动</p>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getSunshineActivity, getAccountActivity } from "../../../api/service";

export default {
  name: "insertActivity",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    accountId: Number,
  },
  data() {
    return {
      theme: "1",
      level: 196,
      levelCheck: [196],
      limit: 1,
      showThemeDialog: false,
      levelList: [],
      activityList: [],
      tabName: "custom",
      url: null,
      urlCheck: [],
      loading: false,
      // 九块九及以下活动id
      nineIds: [196, 74, 84, 75, 186, 159, 76],
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.tabName = "custom";
          this.loading = true;
          this.theme = "1";
          // 自定义活动获取
          getSunshineActivity(this.accountId, 1).then((res) => {
            this.levelList = res;
            this.level = res[0] ? res[0].product_id : null;
            this.levelCheck = res[0] ? [res[0].product_id] : null;
          });
          // 平台活动
          getAccountActivity(this.accountId)
            .then((res) => {
              this.activityList = res;
              this.url = res[0] ? res[0].act_id : null;
              this.urlCheck = res[0] ? [res[0].act_id] : [];
            })
            .finally(() => {
              this.loading = false;
            });
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(val) {
      // 取选中和小于9.9交集
      let tmpArry = val.filter((v) => this.nineIds.includes(v));
      if (tmpArry.length > 0) {
        this.limit = 1;
      } else {
        this.limit = 3;
      }
    },
    handleSingleChoice(val) {
      this.limit = [196, 74, 84, 75, 186, 159, 76].includes(val) ? 1 : 3;
    },
    handleSure() {
      const {
        level,
        theme,
        limit,
        levelList,
        type,
        levelCheck,
        tabName,
        activityList,
        url,
        urlCheck,
      } = this;
      if (type === "text" && levelCheck.length === 0 && urlCheck.length === 0) {
        this.$message.error("请选择充值档位");
        return false;
      }
      let returnData = null;
      // 自定义活动
      if (tabName === "custom") {
        if (type === "text") {
          returnData = levelCheck.map((level) => {
            const levelName = levelList.find(
              (item) => item.product_id === level
            ).name;
            return `<a href="{tpl:${theme},level:${level},limit:${limit}}">${levelName}</a>`;
          });
        } else {
          const name = levelList.find((item) => item.product_id === level).name;
          returnData = {
            // link: `<a href="{tpl:${theme.id},level:${level},limit:${limit}}">${name}</a>`,
            name,
            url: `{tpl:${theme},level:${level},limit:${limit}}`,
          };
        }
      } else {
        // 平台活动
        if (type === "text") {
          returnData = urlCheck.map((level) => {
            const select = activityList.find((item) => item.act_id === level);
            return `<a href="{platformActivityId:${select.act_id}}">${select.intro}</a>`;
          });
        } else {
          const { name, act_id } = activityList.find(
            (item) => item.act_id === url
          );
          returnData = {
            name,
            url: `{platformActivityId:${act_id}}`,
          };
        }
      }
      this.$emit("success", returnData);
      this.handleClose();
    },
    handleClose() {
      this.url = null;
      this.theme = "1";
      this.limit = 1;
      this.level = 196;
      this.levelCheck = [196];
      this.$emit("close");
    },
  },
};
</script>

<style lang='scss' scoped>
  .theme-img {
    width: 220px;
    height: 346px;
  }

  .tips {
    font-size: 14px;
    color: #999;
  }

  .el-checkbox, .radio {
    display: inline-block;
    min-width: 200px;
    margin-left: 0;
    margin-top: 0;
  }

  .radio {
    margin-top: 10px;
  }

  .radio-group {
    height: 520px;
    overflow: auto;
    padding: 20px 0;
    .el-checkbox{
      line-height: 34px;
    }
  }
  .dialog-footer{
     text-align:center;
     margin-top:20px;
   }
</style>
