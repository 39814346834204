<template>
  <el-dialog
    title="创建模板活动"
    :visible.sync="show"
    width="1200px"
    top="10vh"
    append-to-body
    :before-close="handleClose"
  >
    <el-tabs type="border-card" v-model="tabName">
      <el-tab-pane label="自定义活动" name="custom">
        <el-form label-width="80px" style="height: 540px; overflow: auto">
          <el-form-item label="活动主题">
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="https://qcdn.zhangzhongyun.com/promotion/template/template_builtin_preview.jpg"
                alt=""
                class="theme-img"
              />
              <el-radio slot="reference" v-model="theme" label="15"
                >默认主题</el-radio
              >
            </el-popover>
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="https://qcdn.zhangzhongyun.com/promotion/template/template_v2_preview.jpg"
                alt=""
                class="theme-img"
              />
              <el-radio
                slot="reference"
                v-model="theme"
                label="16"
                style="margin-left: 15px"
                >会员活动日</el-radio
              >
            </el-popover>
            <el-popover placement="bottom" width="250" trigger="hover">
              <img
                src="https://qcdn.zhangzhongyun.com/promotion/template/template_v3_preview.jpg"
                alt=""
                class="theme-img"
              />
              <el-radio
                slot="reference"
                v-model="theme"
                label="17"
                style="margin-left: 15px"
                >周末福利</el-radio
              >
            </el-popover>
          </el-form-item>
          <el-form-item label="充值档位">
            <template>
              <el-radio-group
                v-model="level"
                v-if="type === 'news'"
                @change="handleSingleChoice"
              >
                <el-radio
                  :label="item.id"
                  v-for="item in levelList"
                  :key="item.id"
                  class="radio"
                  >{{ item.label }}
                </el-radio>
              </el-radio-group>
              <el-checkbox-group
                v-model="levelCheck"
                v-else
                @change="handleChange"
              >
                <el-checkbox
                  v-for="item in levelList"
                  :key="item.id"
                  :label="item.id"
                  >{{ item.label }}</el-checkbox
                >
              </el-checkbox-group>
              <p class="tips">
                书券有效期默认 365 天；优惠券7天有效 充值金额大于30元才可使用
              </p>
            </template>
          </el-form-item>
          <el-form-item label="充值次数">
            <el-select v-model="limit">
              <el-option :value="1" label="1次"></el-option>
              <el-option :value="2" label="2次"></el-option>
              <el-option :value="3" label="3次"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="平台活动" name="platform">
        <div v-loading="loading" class="radio-group">
          <template v-if="activityList.length">
            <el-radio-group v-model="url" v-if="type === 'news'">
              <el-radio
                :label="item.id"
                v-for="item in activityList"
                :key="item.id"
                class="radio"
                >{{ item.name }}
              </el-radio>
            </el-radio-group>
            <el-checkbox-group v-model="urlCheck" v-else>
              <el-checkbox
                v-for="item in activityList"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </template>
          <p v-else>暂无自定义活动</p>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { readLevelList } from "./theme";
import { getAccountActivity } from "../../../api/service";

export default {
  name: "insertActivity",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    accountId: Number,
  },
  data() {
    return {
      theme: "16",
      level: 48,
      levelCheck: [48],
      limit: 3,
      showThemeDialog: false,
      levelList: readLevelList,
      activityList: [],
      tabName: "custom",
      url: null,
      urlCheck: [],
      loading: false,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.tabName = "custom";
          this.loading = true;
          getAccountActivity(this.accountId)
            .then((res) => {
              this.activityList = res;
              this.url = res[0] ? res[0].id : null;
              this.urlCheck = res[0] ? [res[0].id] : [];
            })
            .finally(() => {
              this.loading = false;
            });
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(val) {
      if (val.includes(40) || val.includes(49) || val.includes(50)) {
        this.limit = 1;
      } else {
        this.limit = 3;
      }
    },
    //test
    handleSingleChoice(val) {
      this.limit = [40, 49, 50].includes(val) ? 1 : 3;
    },
    handleSure() {
      const {
        level,
        theme,
        limit,
        levelList,
        type,
        levelCheck,
        tabName,
        activityList,
        url,
        urlCheck,
      } = this;
      if (type === "text" && levelCheck.length === 0 && urlCheck.length === 0) {
        this.$message.error("请选择充值档位");
        return false;
      }
      let returnData = null;
      if (tabName === "custom") {
        if (type === "text") {
          returnData = levelCheck.map((level) => {
            const levelName = levelList.find((item) => item.id === level).label;
            return `<a href="{tpl:${theme},level:${level},limit:${limit}}">${levelName}</a>`;
          });
        } else {
          const name = levelList.find((item) => item.id === level).label;
          returnData = {
            // link: `<a href="{tpl:${theme.id},level:${level},limit:${limit}}">${name}</a>`,
            name,
            url: `{tpl:${theme},level:${level},limit:${limit}}`,
          };
        }
      } else {
        if (type === "text") {
          returnData = urlCheck.map((level) => {
            const select = activityList.find((item) => item.id === level);
            return `<a href="{platformActivityId:${select.id}}">${select.name}</a>`;
          });
        } else {
          const { name, id } = activityList.find((item) => item.id === url);
          returnData = {
            name,
            url: `{platformActivityId:${id}}`,
          };
        }
      }
      this.$emit("success", returnData);
      this.handleClose();
    },
    handleClose() {
      this.url = null;
      this.theme = "16";
      this.limit = 3;
      this.level = 48;
      this.levelCheck = [48];
      this.$emit("close");
    },
  },
};
</script>

<style lang='scss' scoped>
.theme-img {
  width: 220px;
  height: 346px;
}

.tips {
  font-size: 14px;
  color: #999;
}

.el-checkbox,
.radio {
  display: inline-block;
  min-width: 200px;
  margin-left: 0;
  margin-top: 0;
}

.radio {
  margin-top: 10px;
}

.radio-group {
  height: 520px;
  overflow: auto;
  padding: 20px 0;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
</style>
