<template>
  <div class="insert-btn-cont">
    <el-button
      class="button-mini"
      type="text"
      @click="handleDialogShow"
      style="margin-right: 10px"
      >插入活动</el-button
    >
    <component
      :is="currentView"
      :show="showTemplate"
      :type="type"
      :account-id="channel ? channel.id : null"
      @close="showTemplate = false"
      @success="handleSelectSuccess"
    ></component>
  </div>
</template>

<script>
import YueWenDialog from "./yuewen";
import ReadDialog from "./read";
import SunshineDialog from "./sunshine";
import BookHouseDialog from "./bookhouse";
import QiYueDialog from "./qiyue";
import HuaSheng from "./huasheng";
import HaiDu from "./haidu";
import WangYi from "./wangyi";
import KanShuWang from "./kanshuwang"
export default {
  name: "insertBook",
  props: {
    channel: Object,
    type: String,
  },
  data() {
    return {
      showTemplate: false,
      currentView: null,
    };
  },
  components: {
    ReadDialog,
    YueWenDialog,
    SunshineDialog,
    BookHouseDialog,
    QiYueDialog,
    HuaSheng,
    HaiDu,
    WangYi,
    KanShuWang
  },
  methods: {
    handleDialogShow() {
      if (this.channel) {
        const { platform } = this.channel;
        switch (platform) {
          case 0:
            this.currentView = "ReadDialog";
            break;
          case 1:
            this.currentView = "YueWenDialog";
            break;
          case 3:
            this.currentView = "SunshineDialog";
            break;
          case 4:
            this.currentView = "BookHouseDialog";
            break;
          case 5:
            this.currentView = "WangYi";
            break;
          case 6:
            this.currentView = "QiYueDialog";
            break;
          case 7:
            this.currentView = "HuaSheng";
            break;
          case 8:
            this.currentView = "HaiDu";
            break;
          case 12:
            this.currentView = "WangYi";
            break;
          case 14:
            this.currentView = "KanShuWang";
            break;
        }
        this.showTemplate = true;
      } else {
        this.$message.warning("请输入公众号");
      }
    },
    handleSelectSuccess(select) {
      this.$emit("success", select);
    },
  },
};
</script>

<style scoped>
.insert-btn-cont {
  display: inline-block;
}
</style>
