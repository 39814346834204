<template>
  <div class="insert-btn-cont">
    <el-button
      class="button-mini"
      type="text"
      @click="handleDialogShow"
      style="margin-right: 10px"
      >插入页面</el-button
    >
    <yue-wen-dialog
      :show="showYueWen"
      :account-id="channel ? channel.id : null"
      @close="showYueWen = false"
      @success="handleSelectSuccess"
    />
    <read-dialog
      :show="showRead"
      :account-id="channel ? channel.id : null"
      @close="showRead = false"
      @success="handleSelectSuccess"
    />
    <sunshine-dialog
      :show="showSunshine"
      :account-id="channel ? channel.id : null"
      @close="showSunshine = false"
      @success="handleSelectSuccess"
    />
    <book-house-dialog
      :show="showBookHouse"
      :account-id="channel ? channel.id : null"
      @close="showBookHouse = false"
      @success="handleSelectSuccess"
    />
    <qi-yue-dialog
      :show="showQiYue"
      :account-id="channel ? channel.id : null"
      @close="showQiYue = false"
      @success="handleSelectSuccess"
    />
    <HuaSheng
      :show="showHuaSheng"
      :account-id="channel ? channel.id : null"
      @close="showHuaSheng = false"
      @success="handleSelectSuccess"
    />
    <HaiDu
      :show="showHaiDu"
      :account-id="channel ? channel.id : null"
      @close="showHaiDu = false"
      @success="handleSelectSuccess"
    />
    <WangYiDialog
      :show="showWangYi"
      :account-id="channel ? channel.id : null"
      @close="showWangYi = false"
      @success="handleSelectSuccess"
    />
    <KanShuWangDialog
      :show="showKanShuWang"
      :account-id="channel ? channel.id : null"
      @close="showKanShuWang = false"
      @success="handleSelectSuccess"
    />
  </div>
</template>

<script>
import YueWenDialog from "./yuewen";
import WangYiDialog from "./wangyi";
import ReadDialog from "./read";
import SunshineDialog from "./sunshine";
import BookHouseDialog from "./bookhouse";
import QiYueDialog from "./qiyue";
import HuaSheng from "./huasheng";
import HaiDu from "./haidu";
import KanShuWangDialog from "./kanshuwang";

export default {
  name: "insertPage",
  props: {
    channel: Object,
  },
  data() {
    return {
      showYueWen: false, // 显示阅文
      showRead: false, // 显示掌中云
      showSunshine: false, // 显示阳光书城
      showBookHouse: false, // 显示悠书阁
      showQiYue: false, // 显示七悦
      showHuaSheng: false, // 显示花生
      showHaiDu: false, //显示海读
      showWangYi: false, //显示网易文鼎
      showKanShuWang: false //显示看书网
    };
  },
  methods: {
    handleDialogShow() {
      if (this.channel) {
        const { platform } = this.channel;
        switch (platform) {
          case 0:
            this.showRead = true;
            break;
          case 1:
            this.showYueWen = true;
            break;
          case 3:
            this.showSunshine = true;
            break;
          case 4:
            this.showBookHouse = true;
            break;
          case 5:
            this.showWangYi = true;
            break;
          case 6:
            this.showQiYue = true;
            break;
          case 7:
            this.showHuaSheng = true;
            break;
          case 8:
            this.showHaiDu = true;
            break;
          case 12:
            this.showWangYi = true;
            break;
          case 14:
            this.showKanShuWang = true;
            break;
        }
      } else {
        this.$message.warning("请选择公众号");
      }
    },
    handleSelectSuccess(select) {
      this.$emit("success", select);
    },
  },
  components: {
    ReadDialog,
    YueWenDialog,
    SunshineDialog,
    BookHouseDialog,
    QiYueDialog,
    HuaSheng,
    HaiDu,
    WangYiDialog,
    KanShuWangDialog
  },
};
</script>

<style scoped>
.insert-btn-cont {
  display: inline-block;
}
</style>
