<template>
  <el-dialog
    title="选择页面"
    :visible.sync="show"
    width="800px"
    top="5vh"
    append-to-body
    :before-close="handleClose"
  >
    <el-table
      :data="pageList"
      height="450px"
      v-loading="loading"
      @current-change="(row) => (this.currentPage = row)"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column prop="select" label="选择" width="50">
        <template slot-scope="scope">
          <el-radio
            type="radio"
            :value="currentPage ? currentPage.link : null"
            :label="scope.row.link"
            style="font-size: 0"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="description" label="名称">
        <template slot-scope="scope">
          <span v-if="scope.row.text">{{ scope.row.text }}</span>
          <el-form size="small" label-width="80px" v-else>
            <el-form-item label="链接地址:" style="margin-bottom: 0">
              <el-input
                v-model="scope.row.link"
                placeholder="请输入链接地址"
              ></el-input>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :loading="submitLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getPageList } from "../../../api/service";

export default {
  name: "sunshinePage",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accountId: Number,
  },
  data() {
    return {
      loading: false,
      pageList: [],
      currentPage: {},
      submitLoading: false,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.getPages();
      }
    },
  },
  methods: {
    getPages() {
      this.loading = true;
      getPageList(this.accountId)
        .then((res) => {
          this.pageList = res.concat([{ id: 0, link: "" }]);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleConfirm() {
      const { id, text, link } = this.currentPage;
      if (text) {
        this.$emit("success", {
          link: `<a href="{page:${link}}">${text}</a>`,
          name: text,
          url: `{page:${link}}`,
        });
        this.handleClose();
      } else if (id === 0) {
        if (link) {
          this.$emit("success", {
            link: `<a href="${link}"></a>`,
            name: link,
            url: `${link}`,
          });
          this.handleClose();
        } else {
          this.$message.warning("请输入自定义链接");
        }
      } else {
        this.$message.warning("请选择一个页面");
      }
    },
    handleClose() {
      this.currentPage = {};
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.el-form--inline /deep/ .el-form-item__content {
  width: 300px;
}
</style>